import dynamic                        from "next/dynamic";
import React, { useEffect, useState } from "react";

import Toast                         from "_common/toast";
import { getCreatorDashboardStatus } from "_services/api/creator-dashboard";

const Presentation       = dynamic ( () => import ( "./presentation" ) );
const PresentationMobile = dynamic ( () => import ( "./presentationMobile" ) );

const component = props => {
	const {
		isHomePageNotification,
		isMigratingData,
		isMobile
	} = props;

	const [ modalState, setModalState ] = useState ( false );

	useEffect ( () => {
		setModalState ( isMigratingData );
	}, [ isMigratingData ] );

	const closeModal = () => {
		setModalState ( false );
	};

	if ( !modalState )
		return null;

	const callApi = async () => {
		try {
			const isViewed = 1;

			await getCreatorDashboardStatus ( isViewed );
			/*
        this is a get api but we dont need any response
        as we are appending query params in url endpoint
      */

		} catch ( e ) {
			Toast.e ( e.displayMsg );
		}
	};

	const handleOnClick = () => {
		callApi ();
		window.open ( "/creator-dashboard", "_blank" );
		closeModal ();
	};

	const handleStatusClose = () => {
		callApi ();
		closeModal ();
	};

	const formatModalData = ( { isHomePageNotification } ) => {
		let modalData;

		switch ( isHomePageNotification ) {
			case true: {
				modalData = {
					icon            : "blueTick",
					modalHeading    : "Creator Dashboard is ready!",
					modalSubheading : "Your data is ready to be viewed.",
					buttonText      : "Visit Creator Dashboard",
					onClick         : handleOnClick,
					onClose         : handleStatusClose

				};
				break;
			}

			default: {
				modalData = {
					icon            : "infoFilled",
					modalHeading    : "Stats will be available soon.",
					modalSubheading : "We’re migrating your data. It might take 24h for us to display your statistics.",
					buttonText      : "Okay",
					onClick         : closeModal,
					onClose         : closeModal

				};
			}
		}

		return modalData;
	};

	const modalData = formatModalData ( { isHomePageNotification } );

	if ( isMobile )
		return (
			<PresentationMobile
				modalData = { modalData }
				modalState = { modalState }

			/>
		);

	return (
		<Presentation
			modalData = { modalData }
			modalState = { modalState }

		/>
	);
};

export default component;
