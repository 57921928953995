import { Modal } from "@mantine/core";

import { PrimaryButton } from "_components/atoms/button";
import Icon              from "_components/atoms/icon";
import Typography        from "_components/atoms/typography";

import useStyles from "./styles";

const component = ( {
	isOpen = true,
	onClose,
	loading,
	isUploadingAsNewVideo,
	config
} ) => {

	const { classes } = useStyles ();

	if ( !config ) return null;

	return (
		<Modal
			centered
			classNames      = { { content: classes.root, body: classes.body } }
			onClose         = { onClose }
			opened          = { isOpen }
			size            = { 600 }
			withCloseButton = { false }
		>
			<Icon
				className       = { classes.closeIcon }
				name            = { loading ? "crossIconGrey" : "crossIcon" }
				onClick         = { () => !loading && onClose () }
				sameInBothTheme = { loading }
			/>

			<div className = { classes.mainBody }>
				{ config?.error ? (
					<Icon
						sameInBothTheme
						className = { classes.errorAlertIcon }
						name      = "accountDetailsError"
					/>
				) : (
					<Icon
						sameInBothTheme
						className = { classes.errorAlertIcon }
						name      = "tickFilled"
					/>
				)}

				<Typography
					className = { classes.title }
					title = { config?.title || "Video" }
				/>

				<Typography
					className = { classes.subTitle }
					color     = { "secondary" }
					title     = { config?.subtitle }
				/>

				<PrimaryButton
					fullWidth
					buttonTextClassName = { classes.reuploadBtnText }
					className           = { classes.reuploadBtn }
					disabled            = { loading }
					loading             = { loading }
					onClick             = { config?.primaryAction?.action }
					title               = { config?.primaryAction?.label }
				/>

				{ !config?.secondaryAction ? null : (
					<Typography
						className = { classes.newVideoBtn }
						onClick   = { () => !isUploadingAsNewVideo && config?.secondaryAction?.action }
						title     = { config?.secondaryAction?.label }
						weight    = { "500" }
					/>
				)}

			</div>
		</Modal>
	);
};

export default component;
